import * as React from "react";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
const IBNDemonstraion = ({ location }) => {
  return (
    <Layout pagePath={location.pathname}>
      <div className="flex flex-col">
        <div className="flex justify-center">
          <h1 className=" text-scoringBlue text-center">
            Intent-Driven Management And Orchestration System’s Demonstration
          </h1>
        </div>
        <h3>Introduction</h3>
        <p>
          Intent-based networking is a key pillar in enabling next-generation
          autonomic networks. These networks are characterized by their ability
          to perform self-management, self-healing, and self-operation of
          resources while minimizing the interventions of human operators [1].
          However, although these networks are designed to exhibit
          self-management properties, they still require input from an operator
          or outside system to provide operational guidance and information
          about the goals, purposes, and service instances that the network is
          to serve [1]. This input and operational guidance are commonly
          referred to as "intent", and a network that allows network operators
          to provide their input using intent is referred to as an "Intent-Based
          Network" [1]. Such a paradigm was the context of several research
          studies that proposed applications in different fields. This resulted
          in an important evolution in the definition of intent. The intent is
          defined by the IETF as "A set of operational goals (that a network
          should meet) and outcomes (that a network is supposed to deliver)
          defined in a declarative manner without specifying how to achieve or
          implement them" [1]. Alternatively, TM Forum proposed another
          definition which is considered as the latest definition, it describes
          an intent as "the formal specification of all expectations including
          requirements, goals, and constraints given to a technical system". An
          intent provides utilities, context, and requirements to the management
          system to take deci- sions that satisfy expectations. Figure 1
          represents a comprehensive architecture composed of two main
          subsystems: The Customer Service Portal (CSP), which addresses the
          business layer of the mobile operator. The CSP is responsible for
          capturing the user’s high-level intent, understanding the expressed
          requirements, and translating them into a knowledge graph-based
          intent. The second subsystem is the Autonomous Management and
          Orchestration System (AMANOS), which operates at the service layer of
          the mobile operator. The AMANOS receives the service requests and
          generates the appropriate configuration to satisfy the expressed
          requirements and expectations.
        </p>
        <div className="flex flex-col items-center">
          <StaticImage
            src="../../images/Illustrations-Intent Driven System.png"
            alt="SCORING Work Packages"
            className=" mt-6"
          />
          <p>Figure 1: Intent-Driven SCORING architecture.</p>
        </div>
        <h3>Demonstration Scenario</h3>
        <p>
          The demonstration scenario is depicted in Figure 2. The workflow
          commences with a user interacting with the Customer Service Portal
          subsystem. The user specifies their service requirements, including
          the type of service and various quality parameters such as resolution,
          latency, and throughput. These requirements are articulated in natural
          language format by the user.

          Subsequently, the Intent Recognition Engine translates and extracts a
          set of entities from the user's natural language message. These
          entities form the foundation of the intent object graph, which is
          constructed by the Intent Owner. The intent object graph is then
          forwarded to the Autonomous Management and Orchestration System
          (AMANOS) for further processing of the customer's request.

          Upon receiving the intent, the Intent Manager generates the
          appropriate decisions to fulfill the intent. These decisions are
          subsequently passed to the Policy Generator component to generate the
          corresponding configurations. The orchestrators in the edge and Core
          domains then implement these configurations to deploy the transcoding
          service and the 5G core network functions.

          Concurrently, the deployed service is continuously monitored by the
          Data Manager component. This component provides aggregated views to
          the Intent Monitor, facilitating the validation of intent fulfillment.
          If the intent is not fulfilled, the Intent Monitor requests the intent
          manager to generate a new decision.

          Figure 2 provides a visual representation of the described workflow
          and the various components involved in the demonstration scenario.
        </p>
        <div className="flex flex-col items-center">
          <StaticImage
            src="../../images/Illustrations-Demonstration Scenario.png"
            alt="IBN Demonstration Scenario"
            className=" mt-6"
          />
          <p>Figure 2: A 360° video streaming demonstration scenario.</p>
        </div>
        <h3>Physical Test-Bed</h3>
        <p>
          To evaluate the effectiveness of our proposed framework, we have
          designed an experiment environment as depicted in Figure 3. The
          platform is composed of the following components:
        </p>
        <ul className="list-disc">
          <li className="list-item">
            <i>The Customer Service Portal</i>{" "}
            is deployed in a personal computer using docker-compose. The
            personal computer is characterized by an <i>Intel i7-8750h</i>{" "}
            processor and <i>19630 Mib</i>{" "}
            of memory, a configuration very sufficient for such a subsystem.
          </li>
          <li className="list-item">
            <i>
              The Autonomous Management and Orchestration System (AMANOS)
            </i>{" "}
            is deployed using Docker-Compose in an{" "}
            <i>Intel NUC NUC7I5DNKE</i>. The model is equipped with an{" "}
            <i>Intel i5-7300U (2) 3.5 GHz</i> and a <i>16 GB</i> of memory.
          </li>
          <li className="list-item">
            <i>Network Infrastructure:</i>
            <ul className="list-disc">
              <li className="list-item">
                <i>Multi Access Edge Computing</i> domain composed by a set of
                {" "}
                <i>Intel NUC NUC7I5DNKE</i>{" "}
                forming a Kubernetes cluster to provision the MEC services.
              </li>
              <li className="list-item">
                <i>5G Core</i>{" "}
                domain which follows the same strategy as the MEC domain. It
                includes 5G core network functions such as AMF, UPF, SMF.
                Finally, for user access, a Raspberry PI 4 has been used, which
                is connected to the 5G network using a gnb simulator, as well as
                a UE simulator.
              </li>
            </ul>
          </li>
        </ul>
        <div className="flex flex-col items-center">
          <StaticImage
            src="../../images/Illustrations-Deployment Diagram.png"
            alt="IBN Demonstration's Physical Setup"
            className=" mt-6"
          />
          <p>Figure 3: Experiment Environment</p>
        </div>
        <h3>Demonstration</h3>
        <p>
          In the following video, we will present the results of our
          experimentation regarding testing our system in an experimental test
          lab. We will cover all the steps we experienced during this phase.
        </p>
        <div class="aspect-w-16 aspect-h-9">
          <iframe
            src="https://www.youtube.com/embed/__mpfbZXUKQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>

        <h3 className="mt-16">References</h3>
        <p>
          [1] Alexander Clemm et al. Intent-Based Networking - Concepts and
          Definitions. RFC 9315. Oct. 2022. DOI: 10.17487/RFC9315.
        </p>
      </div>
    </Layout>
  );
};

export default IBNDemonstraion;
